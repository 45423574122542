export class AuthStateModel {
  token?: string;
  username?: string;
  ID?: string;
  name?: string;
  email?: string;
  phone?: string;
  avatar?: string;
  permissions?: any[];
  roles?: string;
  ApiKey?: string;

}
export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: { email?: string, phone?: string, password: string, strategy: string }) { }
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class ForgotPassword {
  static readonly type = '[Auth] ForgotPassword';
  constructor(public payload: { email?: string }) { }
}

export class Register {
  static readonly type = '[Auth] Register';
  constructor(public payload: { name?: string, email?: string, phone: string, password: string }) { }
}
export class Profile {
  static readonly type = '[Auth] Profile';
  constructor(public payload: {
    name?: string,
    email?: string,
    phone?: string,
    bio?: string,
    gender?: string,
    age?: string,
    dob?: string,
    relationship_status?: string,
    avatar?: string,
    qualification?: string,
    speciality?: string,
    hospital?: string,
    consulting_fees?: string,
    rating?: number,
    is_available?: boolean,

    blood_type?: string,
    allergies?: string,
    diseases?: string,
    height?: string,
    weight?: string,



  }) { }
}

export class Update {
  static readonly type = '[Auth] Update';
  constructor(public payload: { ID?: string, email?: string, phone?: string }) { }
}



export class SetApiKey {
  static readonly type = '[Auth] SetApiKey';
  constructor(public payload: { ApiKey?: string }) { }
}


export class SetIpAddress {
  static readonly type = '[Auth] SetIpAddress';
  constructor(public payload: { ip_address?: string }) { }
}
