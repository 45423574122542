import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router,ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { Store } from '@ngxs/store';
import { AlertController, ModalController } from '@ionic/angular';
import { CompanyService } from 'src/app/shared/services/company.service';

@Component({
  selector: 'app-company-metadata',
  templateUrl: './company-metadata.component.html',
  styleUrls: ['./company-metadata.component.scss'],
})
export class CompanyMetadataComponent implements OnInit {

  companyMetadataForm : FormGroup;

  ID: string = "";
  submitted:boolean = false;
  selected_meta: any;

  @Input() metaData: any = '';


  constructor(
    private fb: FormBuilder,
    public translate:TranslateService,
    private router:Router,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    public toaster: ToasterService,
    private store:Store,
    public modalController: ModalController,
    public alertController: AlertController,

  ) {

    this.companyMetadataForm = this.fb.group({
      'meta_group':[null],
      'meta_key':[null],
      'meta_value':[null],
    });
  }

  ngOnInit() {

    // this.route.params.subscribe( params => {

    //   if(params.id){
    //   this.ID = params.id;
    //   this.getData();
    //   }
    // });

    this.getData();
  }

getData(){
  this.companyMetadataForm = this.fb.group({
    'meta_group': this.metaData?.meta_group,
    'meta_key':this.metaData?.meta_key,
    'meta_value':this.metaData?.meta_value,
  });
}

  // getData(){

  //   if(!this.ID){
  //     return
  //   }

  //   this.companyService.get(this.ID).subscribe((item:any)=>{
  //     this.companyMetadataForm.controls['meta_group'].setValue(item.meta_group);
  //     this.companyMetadataForm.controls['meta_key'].setValue(item.meta_key);
  //     this.companyMetadataForm.controls['meta_value'].setValue(item.meta_value);
  //   });
  // }



  // addMetaDataItem(data: any = { date: new Date().toISOString() }, first: boolean = true): void {
  //   this.meta_data = this.companyForm.get('meta_data') as FormArray;
  //   this.meta_data.push(this.createMetaDataItem(data));
  // }


  // submitForm(data){
  //   console.log("save",data);

  //   console.log(data, this.selected_meta);

    // this.addMetaDataItem(data);

    // this.submitted = true;

    // if(this.companyMetadataForm.valid){
    //   this.companyService.save(data,this.ID).subscribe((res:any)=>{

    //     this.toaster.presentToast(this.translate.instant("GLOBAL.SUCCESS.SUCCESS"));

    //     this.dismiss()

    //   },err=>{
    //     if(err.error.code == 401){
    //     }
    //     else{
    //       this.toaster.presentToast(this.translate.instant("GLOBAL.ERROR.SERVER"));
    //      }

    //   });
    // }
    // else{
    //   this.toaster.presentToast(this.translate.instant("GLOBAL.ERROR.VALIDATION"));

    // }
  // }

  submitForm(data) {

    console.log("asa",data);

    this.modalController.dismiss({
      // 'dismissed': true,
      'data': data
    });
  }

  dismiss() {

    this.modalController.dismiss({
      // 'dismissed': true,
      // 'data': data
    });
  }

  async onDelete(id){

    const alert = await this.alertController.create({
      header: this.translate.instant('GLOBAL.ALERT.TITLE'),
      message:this.translate.instant('GLOBAL.ALERT.DELETE'),
      buttons: [
        {
          text:  this.translate.instant('GLOBAL.BTN.CANCEL'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          text: this.translate.instant('GLOBAL.BTN.OK'),
          handler: () => {
            console.log('Items Removed!');
                this.companyService.delete(id).subscribe((res:any)=>{
                  //  this.getData();
                });
          }
        }
      ]
    });

    await alert.present();

  }
}
