import { Component } from '@angular/core';

import { Platform, NavController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Actions, ofActionDispatched, ofActionCompleted, Store } from '@ngxs/store';
import { Logout, Login, Profile } from './auth/state/auth.actions';
import { AuthState } from './auth/state/auth.state';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {

  user: any;

  pages: Array<{ title: string, icon: string, component: any, subPages?: any, display?: boolean, color?: string }>;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private actions: Actions,
    private router: Router,
    public translate: TranslateService,
    private navCtrl: NavController,
    private menu: MenuController,
    private store: Store,
  ) {

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('es');

    this.user = this.store.selectSnapshot(AuthState);
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
      this.router.navigate(['/login']);
    })

    this.actions.pipe(ofActionCompleted(Login)).subscribe((res) => {

      this.user = this.store.selectSnapshot(AuthState);

      this.buildmenu();
    });


    this.actions.pipe(ofActionCompleted(Profile)).subscribe((res) => {
      this.user = this.store.selectSnapshot(AuthState);
    });

    this.translate.get('SIDEBAR.HOME').subscribe((translated: string) => {
      this.buildmenu();
    });

  }

  buildmenu() {

    const mainMenu = [];

    let userMenu = [];
    if (this.user.roles == 'member') {
      userMenu = [
        { title: this.translate.instant("SIDEBAR.DASHBOARD"), component: 'home', icon: 'home' },
        { title: this.translate.instant("SIDEBAR.ACCOUNT"), component: 'profile', icon: 'person-circle' },
      ]
    } else {
      userMenu = [
        { title: this.translate.instant("SIDEBAR.DASHBOARD"), component: 'home', icon: 'home' },
        // { title: this.translate.instant("SIDEBAR.ORDERS"), component: 'orders',icon:'receipt' },
        // { title: this.translate.instant("SIDEBAR.COMPANIES"), component: 'companies',icon:'newspaper' },
        { title: this.translate.instant("SIDEBAR.LOADS"), component: 'search-loads', icon: 'bar-chart' },
        { title: this.translate.instant("SIDEBAR.SHIPPER"), component: 'companies/Shipper', icon: 'expand' },
        { title: this.translate.instant("SIDEBAR.CARRIER"), component: 'companies/Carrier', icon: 'person-circle' },
        { title: this.translate.instant("SIDEBAR.PRODUCT_CATEGORIES"), component: 'product-categories', icon: 'receipt' },
        { title: this.translate.instant("SIDEBAR.PRODUCTS"), component: 'products', icon: 'wallet' },
        { title: this.translate.instant("SIDEBAR.USERS"), component: 'users', icon: 'person' },
        { title: this.translate.instant("SIDEBAR.CITIES"), component: 'cities', icon: 'locate' },
        { title: this.translate.instant("SIDEBAR.STATES"), component: 'states', icon: 'location' },
        { title: this.translate.instant("SIDEBAR.NEWS"), component: 'news', icon: 'receipt' },
        // { title: this.translate.instant("SIDEBAR.MEMBERSHIPS"), component: 'memberships', icon: 'albums' },
        { title: this.translate.instant("SIDEBAR.SETTINGS"), component: 'settings', icon: 'settings' },
      ]

      this.pages = [

        { title: this.translate.instant("SIDEBAR.DASHBOARD"), component: 'dashboard', icon: 'home' },
        { title: this.translate.instant("SIDEBAR.ACCOUNT"), component: 'profile', icon: 'person-circle' },
        { title: this.translate.instant("SIDEBAR.MEMBERS"), component: 'members', icon: 'person-add' },
        { title: this.translate.instant("SIDEBAR.CHAPTERS"), component: 'chapters', icon: 'logo-apple' },
        { title: this.translate.instant("SIDEBAR.CHAPTER_GOALS"), component: 'chapter-goals', icon: 'hourglass' },
        { title: this.translate.instant("SIDEBAR.MEETINGS"), component: 'events/meeting', icon: 'people' },
        { title: this.translate.instant("SIDEBAR.BREAKFAST"), component: 'events/breakfast', icon: 'notifications-circle' },
        { title: this.translate.instant("SIDEBAR.LINE_OF_BUSINESS"), component: 'business-category', icon: 'logo-dropbox' },
        { title: this.translate.instant("SIDEBAR.SUPPORT"), component: 'compliants', icon: 'call' },
        {
          title: this.translate.instant("SIDEBAR.ERP"), component: '', icon: 'cube',
          display: false,
          color: 'medium',
          subPages: [
            { title: this.translate.instant("SIDEBAR.INCOME"), component: 'transactions/income', icon: 'cash' },
            { title: this.translate.instant("SIDEBAR.EXPENSE"), component: 'transactions/expense', icon: 'ribbon' },
            { title: this.translate.instant("SIDEBAR.INVOICES"), component: 'invoices', icon: 'layers' },

          ]
        },

        {
          title: this.translate.instant("SIDEBAR.CRM"), component: '', icon: 'flash',
          display: false,
          color: 'highlight',
          subPages: [
            { title: this.translate.instant("SIDEBAR.LEADS"), component: 'leads', icon: 'medal' },
            { title: this.translate.instant("SIDEBAR.EMPLOYEES"), component: 'employees', icon: 'people-circle' },
            { title: this.translate.instant("SIDEBAR.COMPANIES"), component: 'companies', icon: 'business' },
            { title: this.translate.instant("SIDEBAR.TASKS"), component: 'tasks', icon: 'clipboard' },

          ]
        },

        { title: this.translate.instant("SIDEBAR.LOGOUT"), component: 'logout', icon: 'power' },
      ];

      const footerMenu = [{ title: this.translate.instant('SIDEBAR.LOGOUT'), component: 'logout', icon: 'power' }];

      this.pages = [...mainMenu, ...userMenu, ...footerMenu];
    }
  }

  openPage(p) {

    console.log(p);

    if (p.subPages) {
      p.display = !p.display;
    }
    else {
      console.log(p);

      this.menu.close();
      this.navCtrl.navigateForward(p.component);
    }
  }
}
