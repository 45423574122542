import { State, Action, StateContext, Selector, Select } from '@ngxs/store';
import { AuthStateModel, Login, Logout, Register, Profile, Update, SetApiKey, ForgotPassword } from './auth.actions';
import { AuthService } from '../auth.service';
import { tap, catchError } from 'rxjs/operators'
import { throwError, Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { Injectable } from '@angular/core';

@State<AuthStateModel>({
  name: 'auth'

})
@Injectable()
export class AuthState {

  @Selector()
  static token(state: AuthStateModel) { return state.token; }

  @Selector()
  static apiKey(state: AuthStateModel) { return state.ApiKey; }


  @Selector()
  static user(state: AuthStateModel) { return state; }

  @Selector()
  static userId(state: AuthStateModel) { return state.ID; }


  @Select(state => state.auth) profile$: Observable<string[]>;


  constructor(
    private authService: AuthService,
    private userService: UserService
  ) { }

  @Action(Login)
  login({ patchState }: StateContext<AuthStateModel>, { payload }: Login) {
    return this.authService.login(payload).pipe(

      tap((result: any) => {
        var avatar = "";
        if (result.avatar) {
          avatar = result.avatar;
        }
        else {
          avatar = "./assets/avatar/avatar-male.png";
        }

        let user = result.user;

        patchState({
          token: result.accessToken,
          ID: user._id,
          username: user.phone,
          phone: user.phone,
          email: user.email,
          avatar: avatar,
          permissions: user.permissions,
          name: user.name,

        });
      }),

      //  tap((result: { accessToken: string }) => {
      // patchState({ token:result.accessToken, username: payload.phone });
      // }),
      catchError((err, caught) => {
        return throwError(err);
      })
    );
  }


  @Action(Profile)
  profile({ patchState, getState }: StateContext<AuthStateModel>, { payload }: Profile) {

    const { ID } = getState();

    return this.userService.save(payload, ID).pipe(

      tap((user: any) => {

        console.log(user);

        var avatar = "";
        if (user.avatar) {
          avatar = user.avatar;
        }
        else {
          avatar = "./assets/avatar/avatar-male.png";
        }

        patchState({
          ID: user._id,
          username: user.phone,
          phone: user.phone,
          email: user.email,
          avatar: avatar,
          name: user.name

        });
      }),
      catchError((err, caught) => {
        return throwError(err);
      })
    );
  }

  @Action(ForgotPassword)
  forgotPassword({ patchState, getState }: StateContext<AuthStateModel>, { payload }: Profile) {

    const { ID } = getState();

    let formData = {

      "action": "sendResetPwd",
      //"value": {"_id":  this.user.ID}
      "value": { "email": payload.email },
      //"value": data.email,
      "notifierOptions": { preferredComm: 'email' }

    }

    patchState({
      email: payload.email
    });

    return this.authService.resendToken(formData).pipe(

      tap((user: any) => {
        console.log(user);
      }),
      catchError((err, caught) => {
        return throwError(err);
      })
    );
  }

  @Action(Update)
  update({ patchState }: StateContext<AuthStateModel>, { payload }: Update) {

    patchState({
      ID: payload.ID,
      phone: payload.phone,
      email: payload.email
    });
  }

  @Action(Logout)
  logout({ setState, getState }: StateContext<AuthStateModel>) {
    const { token } = getState();
    //return this.authService.logout(token).pipe(tap(() => {
    return setState({});

    //}));
  }

  @Action(Register)
  register({ patchState }: StateContext<AuthStateModel>, { payload }: Register) {

    return this.userService.save(payload).pipe(

      tap((res: any) => {
        patchState({ ID: res._id, name: res.name, email: res.email, phone: res.phone });
      }),
      catchError((err, caught) => {
        return throwError(err);
      })
    );

  }

  @Action(SetApiKey)
  setApiKey({ patchState }: StateContext<AuthStateModel>, { payload }: SetApiKey) {
    patchState({ ApiKey: payload.ApiKey })
  }
}
