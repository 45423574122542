import { Injectable,Injector } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';
import {  tap } from 'rxjs/operators';


import { Router } from "@angular/router";
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/auth/state/auth.state';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  
  auth:any = '';

  constructor(private inj: Injector,private router:Router, private store:Store) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const token = this.store.selectSnapshot(AuthState.token);

    const ApiKey = this.store.selectSnapshot(AuthState.apiKey);

    


    if(token){

    
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });

      return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              if(token){

                this.router.navigate(['logout']);
                
               
              }
              
            }
          }
        }));
    }
    else if(ApiKey){
      request = request.clone({
        setHeaders: {
          "x-api-key":ApiKey
        }
      });
      return next.handle(request);

    }
    else{
      return next.handle(request.clone());
    }


    

  }
  
}