import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment as env } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient
  ) { }

  login(formData){

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.http.post(env.API_URL+"/authentication",formData, { headers: headers});

  }

  verifyOTP(formData){

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.http.post(env.API_URL+"/authManagement",formData, { headers: headers});

  }


  resendToken(formData){

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.http.post(env.API_URL+"/authManagement",formData, { headers: headers});

  }

  logout(token){
    return this.http.post(env.API_URL+"/logout",token);
  }
}
