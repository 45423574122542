import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivateChild } from '@angular/router';
import { CanActivate } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { AuthState } from './state/auth.state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate,CanActivateChild {

  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(
    private store: Store,
    private router:Router,
    public menu : MenuController
    ) {}

  canActivate() {
    const token = this.store.selectSnapshot(AuthState.token);

    if(!token){
      this.router.navigate(['/login']);
    }
    else{
      this.menu.enable(true);
    }
    
    return true;
  }
  canActivateChild(){
    const token = this.store.selectSnapshot(AuthState.token);

    if(!token){
      this.router.navigate(['/login']);
    }
    return true;
  }

}