import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Store} from '@ngxs/store';
import { AuthState } from './state/auth.state';
import { SetApiKey, SetIpAddress } from './state/auth.actions';


@Injectable()
export class ApikeyResolver implements Resolve<any> {
    constructor(
        private store: Store
        ) {}

    resolve(route: ActivatedRouteSnapshot) {


        const user = this.store.selectSnapshot(AuthState);

        let api_key = route.queryParams.api_key;
        if(api_key){
            this.store.dispatch(new SetApiKey({ApiKey:api_key}))
        }
    
        return true;
            
    }
}
