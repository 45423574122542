import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { CompanyContactsComponent } from './pages/company-contacts/company-contacts.component';
import { CompanyMetadataComponent } from './pages/company-metadata/company-metadata.component';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'company-contact',
    component: CompanyContactsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-metadata',
    component: CompanyMetadataComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-contact/:id',
    component: CompanyContactsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-metadata/:id',
    component: CompanyMetadataComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule),
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./my-account/my-account.module').then(m => m.MyAccountPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/companies/companies.module').then(m => m.CompaniesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-company/add-company.module').then(m => m.AddCompanyPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-setting/add-setting.module').then(m => m.AddSettingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-meta-group/add-meta-group.module').then(m => m.AddMetaGroupPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product-categories',
    loadChildren: () => import('./pages/product-categories/product-categories.module').then(m => m.ProductCategoriesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-product-categories/add-product-categories.module').then(m => m.AddProductCategoriesPageModule),
    canActivate: [AuthGuard]
  },

  {
    path: '',
    loadChildren: () => import('./pages/vehicles/vehicles.module').then(m => m.VehiclesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-vehicle/add-vehicle.module').then(m => m.AddVehiclePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/find-loads/find-loads.module').then(m => m.FindLoadsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-load/add-load.module').then(m => m.AddLoadPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/search-loads/search-loads.module').then(m => m.SearchLoadsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/branches/branches.module').then(m => m.BranchesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-membership/add-membership.module').then(m => m.AddMembershipPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'memberships',
    loadChildren: () => import('./pages/memberships/memberships.module').then(m => m.MembershipsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/shipper/shipper.module').then(m => m.ShipperPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/intermediary/intermediary.module').then(m => m.IntermediaryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'carrier',
    loadChildren: () => import('./pages/carrier/carrier.module').then(m => m.CarrierPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-shipper/add-shipper.module').then(m => m.AddShipperPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-carrier/add-carrier.module').then(m => m.AddCarrierPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-intermediary/add-intermediary.module').then(m => m.AddIntermediaryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/create-load/create-load.module').then(m => m.CreateLoadPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-product/add-product.module').then(m => m.AddProductPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-news/add-news.module').then(m => m.AddNewsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-branch/add-branch.module').then( m => m.AddBranchPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cities',
    loadChildren: () => import('./pages/cities/cities.module').then( m => m.CitiesPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-city/add-city.module').then( m => m.AddCityPageModule)
  },
  {
    path: 'states',
    loadChildren: () => import('./pages/states/states.module').then( m => m.StatesPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/add-state/add-state.module').then( m => m.AddStatePageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
