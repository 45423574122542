import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import {environment as env } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AutoCompleteService } from 'ionic4-auto-complete';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public labelAttribute:string = 'name';
  public formValueAttribute:string = '_id';

  constructor(
    private http:HttpClient
  ) { }

  save(formData:any,ID:string = ""){

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    if(ID){
      return this.http.patch(env.API_URL + '/companies/'+ID,formData, { headers: headers});
    }
    else{
      return this.http.post(env.API_URL + '/companies',formData, { headers: headers});
    }

  }

  get(id:any= ""){

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    if(id){
      return this.http.get(env.API_URL + '/companies/'+id, { headers: headers});
    }
    else{
      return this.http.get(env.API_URL + '/companies', { headers: headers});
    }
    
  }



  getList(sort_field,sort_direction,pageIndex,pageSize,filter){
    
    let sorting_direction = sort_direction=='asc'?1:-1;

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get(env.API_URL + `/companies?$limit=${pageSize}&$skip=${pageSize*pageIndex}&$sort[${sort_field}]=${sorting_direction}${filter}`, { headers: headers});
  }

  delete(id){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.delete(env.API_URL + '/companies/'+id, { headers: headers});

  }

  getCoutry(sort_field,sort_direction,pageIndex,pageSize,filter){
    let sorting_direction = sort_direction=='asc'?1:-1;

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get(env.API_URL + `/countries?$limit=${pageSize}&$skip=${pageSize*pageIndex}&$sort[${sort_field}]=${sorting_direction}${filter}`, { headers: headers});
  }

  getState(sort_field,sort_direction,pageIndex,pageSize,filter){
    let sorting_direction = sort_direction=='asc'?1:-1;

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get(env.API_URL + `/states?$limit=${pageSize}&$skip=${pageSize*pageIndex}&$sort[${sort_field}]=${sorting_direction}${filter}`, { headers: headers});
  }

  getCity(sort_field,sort_direction,pageIndex,pageSize,filter){
    let sorting_direction = sort_direction=='asc'?1:-1;

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get(env.API_URL + `/cities?$limit=${pageSize}&$skip=${pageSize*pageIndex}&$sort[${sort_field}]=${sorting_direction}${filter}`, { headers: headers});
  }

  getResults(keyword:string):Observable<any[]> {
    let observable:Observable<any>;

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    observable =  this.http.get(env.API_URL + `/companies?name[$regex]=${keyword}&name[$options]=i`, { headers: headers});

      return observable.pipe(
        map(
          (result) => {

            return result['data'].filter((item)=>{
              return { name:item.name, _id:item._id }
            })
            
          }
        )
      );
  }

  getItemLabel?(item:any){
    return item.name;
  }

  //   if (this.countries.length === 0) {
  //     observable = this.http.get('https://restcountries.eu/rest/v2/all');
  //   } else {
  //     observable = of(this.countries);
  //   }

  //   return observable.pipe(
  //     map(
  //       (result) => {
  //         return result.filter(
  //           (item) => {
  //             return item.name.toLowerCase().startsWith(
  //                 keyword.toLowerCase()
  //             );
  //           }
  //         );
  //       }
  //     )
  //   );
  // }

}
