import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router,ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { Store } from '@ngxs/store';
import { AlertController, ModalController } from '@ionic/angular';
import { CompanyService } from 'src/app/shared/services/company.service';

@Component({
  selector: 'app-company-contacts',
  templateUrl: './company-contacts.component.html',
  styleUrls: ['./company-contacts.component.scss'],
})
export class CompanyContactsComponent implements OnInit {

  companyContactForm : FormGroup;

  ID: string = "";
  submitted:boolean = false;

  constructor(
    private fb: FormBuilder,
    public translate:TranslateService,
    private router:Router,
    private companyService:CompanyService,
    private route: ActivatedRoute,
    public toaster: ToasterService,
    private store:Store,
    public modalController: ModalController,
    public alertController: AlertController,

  ) {

    this.companyContactForm = this.fb.group({
      'contact_name':[null],
      'contact_name_helper':[null],
      'contact_role_name':[null],
      'contact_role': [null],
      'contact_email':[null],
      'website':[null],
      'contact_phone':[null],
    });
  }

  ngOnInit() {

    this.route.params.subscribe( params => {

      if(params.id){
      this.ID = params.id;
      this.getData();
      }
    });
  }

  getData(){

    if(!this.ID){
      return
    }

    this.companyService.get(this.ID).subscribe((item:any)=>{

      this.companyContactForm.controls['contact_name'].setValue(item.contact_name);
      this.companyContactForm.controls['contact_role'].setValue(item.contact_role);
      this.companyContactForm.controls['contact_email'].setValue(item.contact_email);
      this.companyContactForm.controls['website'].setValue(item.website);
      this.companyContactForm.controls['contact_phone'].setValue(item.contact_phone);

    });
  }

  submitForm(data){
    console.log("save",data);
    this.submitted = true;

    if(this.companyContactForm.valid){
      this.companyService.save(data,this.ID).subscribe((res:any)=>{

        this.toaster.presentToast(this.translate.instant("GLOBAL.SUCCESS.SUCCESS"));

        this.dismiss()

      },err=>{
        if(err.error.code == 401){
        }
        else{
          this.toaster.presentToast(this.translate.instant("GLOBAL.ERROR.SERVER"));
         }

      });
    }
    else{
      this.toaster.presentToast(this.translate.instant("GLOBAL.ERROR.VALIDATION"));

    }
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  async onDelete(id){

    const alert = await this.alertController.create({
      header: this.translate.instant('GLOBAL.ALERT.TITLE'),
      message:this.translate.instant('GLOBAL.ALERT.DELETE'),
      buttons: [
        {
          text:  this.translate.instant('GLOBAL.BTN.CANCEL'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          text: this.translate.instant('GLOBAL.BTN.OK'),
          handler: () => {
            console.log('Items Removed!');
                this.companyService.delete(id).subscribe((res:any)=>{
                   this.getData();
                });
          }
        }
      ]
    });

    await alert.present();

  }
}
