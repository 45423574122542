import { Injectable } from '@angular/core';
import {environment as env } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http:HttpClient
  ) { }

  save(formData:any,ID:string = ""){

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    if(ID){
      return this.http.patch(env.API_URL + '/users/'+ID,formData, { headers: headers});
    }
    else{
      return this.http.post(env.API_URL + '/users',formData, { headers: headers});
    }



  }

  get(id:any= "",filter:any ='',skip:any=0,role:string=""){

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    if(id){
      return this.http.get(env.API_URL + '/users/'+id, { headers: headers});
    }
    else{
      return this.http.get(env.API_URL + `/users?$limit=10&$skip=${skip}${filter}`, { headers: headers});
    }

  }
  getList(sort_field,sort_direction,pageIndex,pageSize,filter,role:string=""){
    
    let sorting_direction = sort_direction=='asc'?1:-1;

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get(env.API_URL + `/users?$limit=${pageSize}&$skip=${pageSize*pageIndex}&$sort[${sort_field}]=${sorting_direction}${filter}`, { headers: headers});
  }

  delete(id){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.delete(env.API_URL + '/users/'+id, { headers: headers});

  }

  checkAccount(formData:any,ID:string = ""){

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    if(ID){
      return this.http.patch(env.API_URL + '/check-account/'+ID,formData, { headers: headers});
    }
    else{
      return this.http.post(env.API_URL + '/check-account/'+formData, { headers: headers});
    }

  }

}
