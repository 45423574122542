import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import {environment as env} from '../environments/environment';

import { TranslateService,TranslatePipe } from '@ngx-translate/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { TokenInterceptor } from './shared/services/token.interceptor';
import { AvatarModule} from 'ngx-avatar';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NetworkComponent } from './shared/components/network/network.component';
import { AvatarComponent } from './shared/components/avatar/avatar.component';

import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

import { AppState } from './shared/state/app.state';
import { AuthService } from './auth/auth.service';
import { AuthState } from './auth/state/auth.state';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { File } from '@ionic-native/file/ngx';
import { NgxUploaderModule } from 'ngx-uploader';
import { AutoCompleteModule } from 'ionic4-auto-complete';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ApikeyResolver } from './auth/apikey.resolver';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ModalModule } from 'ngx-bootstrap/modal'

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule, IonicModule.forRoot(),
        BrowserModule, ModalModule.forRoot(),
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        // AutoCompleteModule,
        // HttpModule,
        NgxsModule.forRoot([
            AppState,
            AuthState
        ], { developmentMode: !env.production }),
        NgxsLoggerPluginModule.forRoot({ disabled: env.production }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: env.production }),
        NgxsStoragePluginModule.forRoot({
            key: 'auth'
        }),
        NgxsFormPluginModule.forRoot(),
        // SocketIoModule.forRoot(config),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        // NgxDatatableModule,
        NgxUploaderModule,
        AvatarModule,
        BrowserAnimationsModule,
        AutoCompleteModule,
        // ChartsModule,
        TypeaheadModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: env.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        TranslatePipe,
        TranslateService,
        NetworkComponent,
        AuthService,
        Camera,
        MediaCapture,
        FileTransfer,
        File,
        ApikeyResolver,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
